var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"margin-bottom":"65px"}},[_c('router-view',{ref:"rv"}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isExactActive),expression:"isExactActive"}]},[_c('b-overlay',{attrs:{"show":_vm.loading,"variant":"transparent","no-wrap":""}}),_c('div',{staticClass:"nav_explore"},[_c('div',{staticClass:"explore_title"},[_vm._v("Explore")]),_c('div',{staticClass:"explore-search"},[_c('autocomplete',{attrs:{"search":_vm.search_song,"placeholder":"gözleg..","aria-label":"gözleg..","get-result-value":_vm.getResultValue},on:{"submit":_vm.onSubmit}})],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticClass:"array-card",staticStyle:{"margin-top":"120px"}},[_c('b-container',{staticClass:"vipSongs"},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('router-link',{attrs:{"to":{
                name: 'Songs',
                params: {
                  title: 'new-songs',
                  api: 'latest',
                  page: 1,
                },
                query: { on: 'Täze' },
              }}},[_c('div',{staticClass:"card-pl bg_new"},[_c('div',{staticClass:"card-tv"},[_vm._v("Täzeler")]),_c('div',{staticClass:"card-img bg-new-sm"})])])],1),_c('b-col',{attrs:{"cols":"6"}},[_c('router-link',{attrs:{"to":{
                name: 'Songs',
                params: {
                  title: 'trend-songs',
                  api: 'trend_page',
                  page: 1,
                },
                query: { on: 'Trend' },
              }}},[_c('div',{staticClass:"card-pl bg-trend"},[_c('div',{staticClass:"card-tv"},[_vm._v("Trend")]),_c('div',{staticClass:"card-img bg-trend-sm"})])])],1),_c('b-col',{attrs:{"cols":"6"}},[_c('router-link',{attrs:{"to":{
                name: 'category',
                params: {
                  title: 'category',
                },
              }}},[_c('div',{staticClass:"card-pl bg-cat"},[_c('div',{staticClass:"card-tv"},[_vm._v("Kategoriya")]),_c('div',{staticClass:"card-img bg-cat-sm"})])])],1),_c('b-col',{staticStyle:{"display":"none"},attrs:{"cols":"6"}},[_c('div',{staticClass:"card-pl bg-artist"},[_c('div',{staticClass:"card-tv"},[_vm._v("Bagşylar")]),_c('div',{staticClass:"card-img bg-artist-sm"})])]),_c('b-col',{attrs:{"cols":"6"}},[_c('router-link',{attrs:{"to":{
                name: 'Songs',
                params: {
                  title: 'most-downloads',
                  navTitle: 'Iň köp ýüklenen',
                  api: 'top_download',
                  page: 1,
                },
                query: { on: 'Iň köp ýüklenen' },
              }}},[_c('div',{staticClass:"card-pl bg-topdown"},[_c('div',{staticClass:"card-tv"},[_vm._v("Iň köp ýüklenen")]),_c('div',{staticClass:"card-img bg-topdown-sm"})])])],1),_c('b-col',{attrs:{"cols":"6"}},[_c('router-link',{attrs:{"to":{
                name: 'Songs',
                params: {
                  title: 'popular',
                  navTitle: 'Populýarny',
                  api: 'top_rate',
                  page: 1,
                },
                query: { on: 'Populýarny' },
              }}},[_c('div',{staticClass:"card-pl bg-popular"},[_c('div',{staticClass:"card-tv"},[_vm._v("Populýarny")]),_c('div',{staticClass:"card-img bg-popular-sm"})])])],1),_vm._l((_vm.playlist),function(playlist){return _c('b-col',{key:playlist.pid,attrs:{"cols":"6"}},[_c('router-link',{attrs:{"to":{
                name: 'PlaylistSongs',
                params: {
                  id: playlist.pid,
                  title: _vm.song_detail(playlist.playlist_name),
                  page: 1,
                },
                query: { on: playlist.playlist_name },
              }}},[_c('div',{staticClass:"card-pl"},[_c('div',{staticClass:"card-tv"},[_vm._v(_vm._s(playlist.playlist_name))]),_c('progressive-img',{staticClass:"card-img",attrs:{"blur":0,"placeholder":"https://alemsesi.com/api/placeholder.webp","fallback":"https://alemsesi.com/api/placeholder.webp","src":playlist.playlist_image_thumb}})],1)])],1)})],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }