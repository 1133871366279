var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.single1),function(single1){return _c('div',{key:single1.songs_list[0].id,staticClass:"singleSong",style:({ background: _vm.dark })},[_c('router-link',{attrs:{"to":{
           name: 'SongDetail',
           params: {
             id: single1.songs_list[0].id,
             slug: _vm.song_detail(single1.songs_list[0].mp3_title),
             singer: single1.songs_list[0].mp3_artist,
             title: single1.songs_list[0].mp3_title,
             
           },
           query: {
             singer: _vm.getSinger(single1.banner_title, single1.banner_title),
           },
         }}},[_c('img',{staticClass:"imgSingle",attrs:{"id":"target-image","src":single1.banner_image,"alt":single1.songs_list[0].mp3_title,"text-left":"","width":"100%","height":"auto"}})])],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }