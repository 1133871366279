<template>
  <b-row>
    <b-col cols="6" v-for="vipSongs in vipSongs" :key="vipSongs.id">
     <router-link
          :to="{
            name: 'SongDetail',
            params: {
              id: vipSongs.id,
              slug: song_detail(vipSongs.mp3_title),
              singer: vipSongs.mp3_artist,
              title: vipSongs.mp3_title,
            },
            query: { singer: getSinger(vipSongs.mp3_artist, vipSongs.category_name) },
          }"
        >
        <div
          class="vip_song clearfix"
        >
          <progressive-img
            class="p_img"
            :blur="0"
            :alt="vipSongs.mp3_title"
            :src="vipSongs.mp3_thumbnail_s"
            placeholder="https://alemsesi.com/api/placeholder.webp"
            fallback="https://alemsesi.com/api/placeholder.webp"
          />
          <p class="vip_song_title">{{ vipSongs.mp3_title }}</p>
        </div>
      </router-link>
    </b-col>
  </b-row>
</template>
<script>
export default {
  data() {
    return {
      title: ``,
      artist: "",
    };
  },
  name: "Songs",
  props: ["vipSongs"],
  methods: {
    song_detail(title) {
      var slug = "";
      var titleLower = title.toLowerCase();
      slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, "e");
      slug = slug.replace(/a|á|à|ã|ả|ạ|ä|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, "a");
      slug = slug.replace(/o|ó|ò|õ|ỏ|ö|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, "o");
      slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ü|ử|ự/gi, "u");
      slug = slug.replace(/c|ç/gi, "c");
      slug = slug.replace(/n|ň/gi, "n");
      slug = slug.replace(/s|ş/gi, "s");
      slug = slug.replace(/y|ÿ|ý/gi, "y");
      slug = slug.replace(/đ/gi, "d");
      slug = slug.replace(/\s*$/g, "");
      slug = slug.replace(/ /g, "-");

      return slug;
    },
     getSinger(text, cat) {
      if (
        (text == "Beýlekiler (Other)") |
        (text == "Halk aýdymlary") |
        (text == "Goşgular (Lyrics)") |
        (text == "Daşary ýurt aýdymlary")
      ) {
        
        
        var catName = cat.replace(/ /g, "");
        return catName;
      } else {
        var noSpacesString = text.replace(/ /g, "");
        return noSpacesString;
      }
    },
  },
};
</script>

<style scoped>
.row > * {
  padding-right: calc(var(--bs-gutter-x) * 0);
  padding-left: calc(var(--bs-gutter-x) * 0);
}
.vip_song {
  background: #181818;
  margin-bottom: 8px;
  height: 50px;
  margin-right: 8px;
  border-radius: 3px;
}
.p_img {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  width: 50px;
  height: 50px;
  float: left;
  margin-right: 10px;
}
.vip_song_title {
  text-align: left;
  display: -webkit-box;
  vertical-align: middle;
  -webkit-line-clamp: 2;
  font-family: "bold", sans-serif;
  line-height: 1.5;
  padding-top: 8px;
  color: #b6b6b6;
  padding-right: 5px;
  font-size: 11px;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
