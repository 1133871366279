<template>
  <div>
    <div>
      <div class="login_page" v-if="!this.$cookies.get('_getuser')">
        <div class="login-head" style="margin-top: 40px">
          <div class="login-title">Täze Hasap aç</div>
          <div class="login-body">
            Aýdymlara teswir ýazmak, like we dislike goýmak we ähli
            mümkinçiliklerden peýdalanmak üçin hasap açyň
            <br />
          </div>
        </div>
        <div class="login-form">
          <b-form>
            <b-form-input
              type="number"
              placeholder="Telefon +993.."
              id="phone"
              required
              v-model="phone"
            ></b-form-input>
            <br />

            <b-form-input
              type="text"
              placeholder="Adyňyz"
              required
              id="username"
              v-model="username"
            ></b-form-input>
            <br />
            <b-form-input
              type="password"
              placeholder="Parolyňyz"
              required
              id="password"
              v-model="password"
            ></b-form-input>

            <div class="login-body">
              Dowam etmek üçin aşakdaky gutyjygy bellik ediň.
            </div>
            <b-button
              hidden
              style="width: 100%"
              block
              variant="success"
              v-show="!loading"
              @click="registerUser()"
              >Hasap aç</b-button
            >
            <b-button
              style="width: 100%"
              variant="success"
              v-show="loading"
              disabled
            >
              <b-spinner small></b-spinner>
              Garaşyň...
            </b-button>
            <vue-recaptcha
            style="display: inline-table;"
              @verify="registerUser(phone, username, password)"
              size="visible"
              sitekey="6LfylYseAAAAAJUcxggezTXDYojXvOcaplz7oHKf"
            />
          </b-form>
          <br />
          <div class="login-body">
            Öň Hasabyň barmy? Aşakdaka basyp öňki hasabyňyza girip bilersiňiz.
          </div>
          <br />

          <b-button style="width: 100%" @click="toLogin()" variant="danger"
            >Içeri gir</b-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueRecaptcha } from "vue-recaptcha";
export default {
  components: { VueRecaptcha },
  data: () => ({
    phone: "",
    password: "",
    username: "",
    user: "",
    userreg:"",
    profile: "",
    loading: false,
    earnlist: "",
    earnhistory: "",
    loadearn: false,
    loadhistory: false,
    robot: false,
  }),
  created() {},
  beforeMount() {
    if (this.$cookies.get("_getuser")) {
      this.loadProfile();

      console.log("mount");
    }
  },

  methods: {
    verifyrcpt() {},
    regs() {
      // when you need a reCAPTCHA challenge
      this.$refs.recaptcha.execute();
    },
    showMsgBoxOne(msg) {
      this.boxOne = "";
      this.$bvModal
        .msgBoxConfirm(msg, {
          okTitle: "Hawa",
          cancelTitle: "Ýok",
          okVariant: "danger",
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.$cookies.remove("_getuser");
            this.$cookies.remove("_setuser_key");
            this.$router.push("/");
          } else {
            console.log("dowam");
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    toLogin() {
      this.$router.push("/profile");
    },
    showStaticInfo(title, body) {
      this.boxTwo = "";
      this.$bvModal
        .msgBoxOk(body, {
          title: title,
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        })
        .then((value) => {
          this.boxTwo = value;
        })
        .catch((err) => {
          // An error occurred
        });
    },
    async registerUser(phone, passw, username) {
      this.loading = true;
      try {
        const headers = new Headers({
          'Authorization': 'Basic ' + this.$store.getters.token,
        });
        const response = await fetch(
          "https://alemsesi.com/api/wep_api.php?register_user&phone=" +
            phone +
            "&password=" +
            passw +
            "&name=" + username
        ,{headers});

        const data = await response.json();
        this.userreg = data.ONLINE_MP3;
        this.loading = false;
        console.log(this.user)

        if (this.userreg[0].success == 1) {
          this.login(phone,passw)
        } else if (this.userreg[0].success == 0) {
          this.showStaticInfo(
            "Ýalňyşlyk",
            "Bu Telefon belgä degişli öň hem bir hasap bar. "
          );
        } else {
          console.log("error");
          this.showStaticInfo(
            "Ýalňyşlyk",
            "Täzeden barlaň"
          );
        }
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
     async login(ph, ps) {
      this.loading = true;
      try {
        const headers = new Headers({
          'Authorization': 'Basic ' + this.$store.getters.token,
        });
        const response = await fetch(
          "https://alemsesi.com/api/wep_api.php?user_login&phone=" +
            ph +
            "&password=" +
            ps
        ,{headers});

        const data = await response.json();
        this.user = data.ONLINE_MP3;
        this.loading = false;

        if (this.user[0].success == 1) {
          this.$cookies.set("_getuser", this.user[0].user_id);
          this.$cookies.set("_setuser_key", ps);
         this.$router.push("/profile");
        } 
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.big_title {
  font-size: 18px;
  margin-left: 5px;
  color: white;
  text-align: left;
  font-family: "bold", sans-serif;
}
.length_txt {
  position: relative;
  width: 26px;
  right: -35px;
  overflow: hidden;
}

.profile-h-content {
  padding-bottom: 15px;
}
.profile-head {
  position: relative;
  overflow: hidden;
  background: #202020;
  margin: 10px;
  border-radius: 10px;
  padding: 15px;
}
.prof-static {
  font-family: "light", sans-serif;
  color: white;
  margin-top: 5px;
  font-size: 12px;
}
.profile-head-st img {
  width: 17px;
}
.profile-head-st {
  position: relative;
  overflow: hidden;
  padding-bottom: 7px;
  padding-top: 7px;
  background: #202020;
  color: white;
  font-family: "bold", sans-serif;
  font-size: 14px;
  margin: 10px;
  border-radius: 10px;
}
.edit-profile {
  text-align: left;
  margin-left: 5px;
}
.logout-profile {
  top: 13px;
  position: absolute;
  right: 15px;
}
.edit-profile img {
  width: 19px;
  height: 19px;
}
.logout-profile img {
  width: 19px;
  height: 19px;
}
.pf-phones {
  font-family: "light", sans-serif;
  color: rgb(194, 194, 194);
  font-size: 13px;
  margin-top: 5px;
}
.pf-name {
  font-family: "bold", sans-serif;
  font-size: 19px;
  color: white;
}
.pf-img {
  width: 90px;
  height: 90px;
  border-radius: 100%;
}
.login-form {
  margin-top: 20px;
  padding-right: 25px;
  padding-left: 25px;
}
.login-head {
  padding: 10px;
  margin-top: 20px;
}
.login-title {
  color: white;
  font-size: 19px;
  font-family: "bold", sans-serif;
}
.login-body {
  color: rgb(201, 201, 201);
  font-family: "light", sans-serif;
  font-size: 14px;
  line-height: 1.7;
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
}
</style>
