var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('router-link',{attrs:{"to":{
      name: 'Songs',
      params: {
        title: 'trend-songs',
        navTitle: 'Trend',
        api: 'trend_page',
        page: 1,
        props: true,
      },
      query: { on: 'Trend' },
    }}},[_c('div',{staticClass:"next_btn"},[_c('img',{attrs:{"src":require("@/assets/icons/next_ic.svg")}})])]),_c('div',{staticClass:"text-left"},[_vm._v("Bu hepde trend")]),_c('div',{staticClass:"scrolling-wrapper"},_vm._l((_vm.trend),function(trend){return _c('div',{key:trend.id,staticClass:"card"},[_c('router-link',{attrs:{"to":{
          name: 'SongDetail',
          params: {
            id: trend.id,
            slug: _vm.song_detail(trend.mp3_title),
            singer: trend.mp3_artist,
            title: trend.mp3_title,
          },
          query: { singer: _vm.getSinger(trend.mp3_artist, trend.category_name) },
        }}},[_c('progressive-img',{staticClass:"p_img",attrs:{"src":trend.mp3_thumbnail_s,"alt":trend.mp3_title,"blur":0,"placeholder":"https://alemsesi.com/api/placeholder.webp","fallback":"https://alemsesi.com/api/placeholder.webp"}})],1)],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }