<template>
  <div id="app">
    
    <div id="bottom_nav">
     
      <router-link to="/">
        <div id="bottomItem">
          <img class="bottomIcon" src="@/assets/icons/home_ic.svg" />
          <img class="bottomIcon_bold" src="@/assets/icons/home_ic_bold.svg" />
          <div class="bottom_text">Home</div>
        </div>
      </router-link>
      <router-link to="/explore">
        <div id="bottomItem">
          <img class="bottomIcon" src="@/assets/icons/search_ic.svg" />
          <img
            class="bottomIcon_bold"
            src="@/assets/icons/search_ic_bold.svg"
          />

          <div class="bottom_text">Explore</div>
        </div>
      </router-link>

      <router-link to="/library" style="display:none">
        <div id="bottomItem">
          <img class="bottomIcon" src="@/assets/icons/library_ic.svg" />
          <img
            class="bottomIcon_bold"
            src="@/assets/icons/library_ic_bold.svg"
          />

          <div class="bottom_text">Library</div>
        </div>
      </router-link>
      <router-link to="/profile">
        <div id="bottomItem">
          <img class="bottomIcon" src="@/assets/icons/profile_ic.svg" />
          <img
            class="bottomIcon_bold"
            src="@/assets/icons/profile_ic_bold.svg"
          />
          <div class="bottom_text">Profile</div>
        </div>
      </router-link>
    </div>
    <router-view />
  </div>
</template>

<script>

export default {
  
  name: "App",
  components:{
    
  },
  
  metaInfo: {
    title: "Älem Sesi - trend we täze aýdymlar",

    htmlAttrs: {
      lang: "en-US",
    },
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content: "Täze we diňe trend aýdymlaryň bolan ýeri Älem Sesi",
      },
      {
        name: "viewport",
        content:
          "width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=0",
      },
    ],
  },
};
</script>

<style>
.router-link-exact-active .bottomIcon_bold {
  display: unset;
}
.router-link-exact-active .bottomIcon {
  display: none;
}
.bottomIcon_bold {
  display: none;
}
.router-link-exact-active {
  font-family: "bold", sans-serif !important;
}
.nav_title {
  font-family: "bold", sans-serif;
  color: white;
  vertical-align: middle;
  margin-left: 10px;
  font-size: 20px;
}
.song_data_l {
  text-align: left;
}
.s_title {
  font-family: "bold", sans-serif;
  color: white;
  font-size: 15px;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 5px;
  display: -webkit-box;
  vertical-align: middle;
  -webkit-line-clamp: 1;
}

.s_artist {
  margin-top: 5px;
  font-family: "light", sans-serif;
  color: rgb(196, 196, 196);
  font-size: 13px;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  vertical-align: middle;
  -webkit-line-clamp: 1;
}
.song_img_l {
  height: 60px !important;
  width: 60px !important;
  float: left;
  margin-right: 15px;
}
.song {
  height: 60px;
  margin-bottom: 20px;
}
.songs {
  padding: 10px;
}
.songs_title {
  color: rgb(204, 204, 204);
  margin-bottom: 10px;
  font-size: 15px;
  font-family: "bold", sans-serif;
  text-align: left;
}
.n_title {
  text-align: left;
  color: white;
  font-family: "bold", sans-serif;
  margin-top: 15px;
  margin-left: 10px;
}
.n_close img {
  float: right;
  right: 20px;
  width: 24px;
  padding: 5px;
  height: 24px;
  color: white;
  top: 15px;
  position: absolute;
}
.nav_comm {
  overflow: hidden;
  height: 50px;
  background: #171717;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 5px 1px 5px 1px rgb(0 0 0 / 25%);
}
.sl_panel {
  background: #171717;
  position: absolute;
  z-index: 99999;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: scroll;
}
.comment_block {
  padding: 10px;
  border-bottom: 1px solid #272727;
}
.comment_img {
  width: 28px !important;
  height: 28px;
  border-radius: 100%;
  margin-left: 5px;
  object-fit: cover;
  padding: 2px;
  float: left;
  margin-right: 10px;
  margin-top: 4px;
  transform: translateZ(0);
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
}
.comment_author {
  text-align: left;
  font-size: 12px;
  font-family: "light", sans-serif;
  color: #b3b3b3;
}

.comment_body {
  text-align: left;
  font-size: 13px;
  font-family: "light", sans-serif;
  color: #ffffff;
  margin-left: 44px;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  vertical-align: middle;
  -webkit-line-clamp: 1;
}
.comment_title {
  color: white;
  margin-bottom: 10px;
  font-size: 12px;
  font-family: "light", sans-serif;
  text-align: left;
}
.control_tv {
  color: #ffffff;
  font-family: "light", sans-serif;
  font-size: 12px;
  margin-top: 5px;
}
.control_ic {
  width: 26px;
  height: 26px;
  padding: 2px;
}
.song_control {
  border-top: 1px solid #272727;
  border-bottom: 1px solid #272727;
}
.btn_col {
  padding-top: 10px;
  padding-bottom: 10px;
}
.song_info_collapsed {
  padding-right: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
}

.song_desc {
  text-align: left;
  font-family: "light", sans-serif;
  font-size: 13px;
  margin-top: 4px;
  line-height: 1.6;
  color: rgb(192, 192, 192);
}
.down_count {
  text-align: left;
  font-family: "light", sans-serif;
  font-size: 13px;
  color: rgb(192, 192, 192);
}
.arrow_collap {
  position: absolute;
  top: 35px;
  right: 30px;
}
.arrow_collap img {
  width: 19px;
  height: 19px;
}
.star_rating {
  float: left;
  margin-top: 6px;
  margin-left: 7px;
}
.total_rate {
  float: left;
  color: rgb(192, 192, 192);
  font-size: 12px;
  font-family: "light", sans-serif;
  margin-top: 8px;
}

.total_view {
  float: left;
  color: rgb(192, 192, 192);
  font-size: 12px;
  margin-left: 10px;
  font-family: "light", sans-serif;
  margin-top: 8px;
}
.total_rate:before {
  content: "";
  display: block;
  background: url("~@/assets/icons/totalrate_ic.svg") no-repeat;
  width: 14px;
  height: 14px;
  background-size: 12px;
  margin-top: 2px;
  margin-right: 4px;
  float: left;
}
.song_info {
  padding: 10px;
  margin-top: 0px;
  padding-bottom: 2px;
  position: relative;
  overflow: hidden;
}
.song_title {
  text-align: start;
  font-size: 17px;
  color: white;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  vertical-align: middle;
  -webkit-line-clamp: 2;
  font-family: "bold", sans-serif;
  line-height: 1.4;
}

.song_artist {
  text-align: start;
  font-size: 14px;
  color: rgb(192, 192, 192);
  margin-top: 5px;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  vertical-align: middle;
  -webkit-line-clamp: 2;
  font-family: "light", sans-serif;
  line-height: 1.2;
}
.header_song {
  height: 265px;
  position: relative;
  overflow: hidden;
}
.sliding-panel {
  background: white;
  left: 0;
  right: 0;
}
.big_img {
  margin: auto;
  position: absolute;
  top: 30px;
  left: 0;
  right: 0;
  border: 0;
  z-index: 9;
  border-radius: 10px;
  -o-object-fit: cover;
  object-fit: cover;
}
.blur_img {
  height: 265px;
  opacity: 0.4;
  width: 100%;
  position: absolute;
  filter: blur(8px);
  top: 0;
  -webkit-filter: blur(8px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.b-overlay .spinner-border {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  vertical-align: text-bottom;
  border: 0.1em solid #fff;
  border-right: 0.1em solid transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}
a {
  text-decoration: none !important;
}
body {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.scrolling-wrapper::-webkit-scrollbar {
  display: none;
}
.next_btn img {
  float: right;
  width: 23px;
  margin-top: 2px;
  margin-right: 11px;
}
@font-face {
  font-family: "light";
  src: local("light"), url(./fonts/light.ttf) format("truetype");
}
@font-face {
  font-family: "bold";
  src: local("bold"), url(./fonts/bold.ttf) format("truetype");
}
body {
  margin: 0px;
  background: #131313 !important;
}
#app {
  font-family: Avenir, Helvetica, Arial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#bottom_nav {
  position: fixed;
  bottom: 0;

  width: 100%;
  z-index: 999;
  justify-content: space-around;
  display: flex;
  height: 55px;
  background: #171717;
}

#bottom_nav a {
  text-decoration: none;
  color: #e4e4e4 !important;
  font-size: 9px;
  font-family: "light";
  margin-top: 2px;
}

#bottomItem {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 6px;
  padding-bottom: 5px;
  text-align: center;
}
#bottomItem img {
  width: 18px;
  margin-top: 3px;
  margin-bottom: 5px;
  height: 18px;
}
#carousel-fade img{
  height: 170px;
  object-fit: cover;  
  border-radius: 10px;
}
.VueCarousel-wrapper {
    width: 100%;
    position: relative;
    overflow: hidden;
    height: 170px;
}
</style>
