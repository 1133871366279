<template>
<div>
  
    <div class="text-left">Goşgylar</div>
  <div class="scrolling-wrapper">
  <div  v-for="poetry in poetry" :key="poetry.id" class="card_hr">
    <router-link
          :to="{
            name: 'SongDetail',
            params: {
              id: poetry.id,
              slug: song_detail(poetry.mp3_title),
              singer: poetry.mp3_artist,
              title: poetry.mp3_title,
            },
            query: { category: getSinger(poetry.mp3_artist, poetry.category_name) },
          }"
        >
      <progressive-img 
          :src="poetry.mp3_thumbnail_s"
          class="p_img"
          :alt='poetry.mp3_title'
          :blur="0"
          placeholder="https://alemsesi.com/api/placeholder.webp"
          fallback="https://alemsesi.com/api/placeholder.webp"
        />
      <div class="song_title">{{poetry.mp3_title}}</div>
      <div class="song_artist">{{poetry.mp3_artist}}</div>
      </router-link>
      </div>
</div>
</div>
</template>

<style scoped>
.scrolling-wrapper {
    margin-left: 5px;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
   -webkit-overflow-scrolling: touch;
}
 .card_hr {
     margin: 5px;
     background: none;
    display: inline-block;
  }
  .text-left{
      text-align: left;
      font-size: 19px;
      margin-left: 10px;
      overflow-x: hidden;
      margin-bottom: 5px;
      font-family: 'bold' ,sans-serif;
      overflow: hidden;
      color: white;
  }
  .song_title{
 text-align: left;
display: -webkit-box;
vertical-align: middle;
-webkit-line-clamp: 2;
font-family: 'light',sans-serif;
line-height: 1.0;
height: auto;
padding-top: 1px;
color: #f2f2f2;
max-width: 120px;
padding-right: 5px;
font-size: 11px;
-webkit-box-orient: vertical;
overflow: hidden;
text-overflow: ellipsis;
  }

   .song_artist{
 text-align: left;
display: -webkit-box;
vertical-align: middle;
-webkit-line-clamp: 2;
font-family: 'light',sans-serif;
line-height: 1.3;
height: auto;
padding-top: 2px;
color: #B6B6B6;
width: 120px;
padding-right: 5px;
font-size: 11px;
-webkit-box-orient: vertical;
overflow: hidden;
text-overflow: ellipsis;
  }
    .p_img{
  
    /* position: absolute; */
    /* top: 0; */
    /* left: 0; */
    /* width: 100%; */
    height: 120px;
  width: 120px;
  border-radius: 5px;
  object-fit: cover;
    /* height: auto; */
   

  }
</style>


<script>

export default ({
  name: 'poetry',
  props:['poetry'],
  methods:{
    song_detail(title) {
      var slug = "";
      var titleLower = title.toLowerCase();
      slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, "e");
      slug = slug.replace(/a|á|à|ã|ả|ạ|ä|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, "a");
      slug = slug.replace(/o|ó|ò|õ|ỏ|ö|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, "o");
      slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ü|ử|ự/gi, "u");
      slug = slug.replace(/c|ç/gi, "c");
      slug = slug.replace(/n|ň/gi, "n");
      slug = slug.replace(/s|ş/gi, "s");
      slug = slug.replace(/y|ÿ|ý/gi, "y");
      slug = slug.replace(/đ/gi, "d");
      slug = slug.replace(/\s*$/g, "");
      slug = slug.replace(/ /g, "-");

      return slug;
    },
    getSinger(text, cat) {
      if (
        (text == "Beýlekiler (Other)") |
        (text == "Halk aýdymlary") |
        (text == "Goşgular (Lyrics)") |
        (text == "Daşary ýurt aýdymlary")
      ) {
        
        
        var catName = cat.replace(/ /g, "");
        return catName;
      } else {
        var noSpacesString = text.replace(/ /g, "");
        return noSpacesString;
      }
    },
  },

});



</script>

