<template>
  <div class="home" style="margin-bottom: 65px">
    <div class="navbarCustom">
      <div class="logo-left">
        <img class="logo_ic" src="@/assets/logo_ic.svg" />
        <img class="logo_big" src="@/assets/logo.svg" />
      </div>
    </div>
    <b-overlay :show="loading" variant="transparent" no-wrap> </b-overlay>
    <div class="home_page" v-if="loaded" style="margin-top: 60px; margin-left: 10px; margin-right: 10px;">
      
      <div >
          <HomeReklamaVue :homeReklama="homeReklama" />
        </div>
      

        <div style="margin-top: 15px">
        <TrendingSongsVue :trend="trend" />
      </div>    
      <div>       
        <div>
          <HomeBigSongVue :single="single" />
        </div>      
      </div>
      
      <div style="margin-top: 15px">
        <PlaylistHomeVue :playlist="playlist" />
      </div>      


      <div style="margin-top: 5px">
        <LatestSongsVue :latest="latest" />
      </div>
      
      <div style="margin-top: 15px">
        <HomeBigSongCenter :single1="single1" />
      </div>
      <b-container class="vipSongs">
        <VipSongsVue :vipSongs="vipSongs" />
      </b-container>
      <div style="margin-top: 15px">
        <PoetrySongsVue :poetry="poetry" />
      </div>
      
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import VipSongsVue from "../components/VipSongs.vue";
import HomeReklamaVue from "../components/HomeReklama.vue";
import LatestSongsVue from "../components/LatestSongs.vue";
import TrendingSongsVue from "../components/TrendingSongs.vue";
import PoetrySongsVue from "../components/PoetrySongs.vue";
import HomeBigSongVue from "../components/HomeBigSong.vue";
import HomeBigSongCenter from "../components/HomeBigSongCenter.vue"
import PlaylistHomeVue from "../components/PlaylistHome.vue";
export default {
  
  name: "Home",
  data() {
    

    return {      
      loaded: true,
      loading: false,
      vipSongs: "",
      homeReklama: "",
      trend: "",
      latest: "",
      poetry: "",
      single: "",
      single1: ""
    };
  },
  computed: {
  token() {
    return this.$store.state.auth.token;
    
  },
},
  components: {
    PlaylistHomeVue,
    HomeReklamaVue,
    VipSongsVue,
    TrendingSongsVue,
    LatestSongsVue,
    PoetrySongsVue,
    HomeBigSongVue,
    HomeBigSongCenter
    
},
  async created() {
    
    this.loading = true;
    this.loaded = false;
    
    try {
      
      
      const response = await fetch(
        "https://alemsesi.com/api/wep_api.php?home"
      );
      const data = await response.json();
      this.vipSongs = data.ONLINE_MP3.vip_songs;
      this.homeReklama = data.ONLINE_MP3.home_reklama;
      this.trend = data.ONLINE_MP3.trending_songs;
      this.latest = data.ONLINE_MP3.new_songs;
      this.poetry = data.ONLINE_MP3.lyrics_songs;
      this.single = data.ONLINE_MP3.big_mp3;
      this.single1= data.ONLINE_MP3.home_banner;
      this.playlist = data.ONLINE_MP3.playlistHome;
      this.loading = false;
      this.loaded = true;
      console.log("bannersa",this.playlist);

    } catch (error) {
      console.log(error);
      this.loaded = false;
      this.loading = false;
    }
  },
};
</script>

<style>
.navbarCustom {
  padding-top: 15px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  position: fixed;
  top: 0;
  z-index: 99999;
  width: 100%;
  height: 70px;
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0),
    rgb(23, 23, 23, 1)
  );
}
.logo-left {
  margin-left: 0px;
  float: left;
}
.logo_ic {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.logo_big {
  height: 23px;
}
.vipSongs {
  margin-left: 10px !important;
  margin-right: 2px !important;
  margin-top: 20px;
  width: auto !important;
}

</style>
