var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',_vm._l((_vm.vipSongs),function(vipSongs){return _c('b-col',{key:vipSongs.id,attrs:{"cols":"6"}},[_c('router-link',{attrs:{"to":{
          name: 'SongDetail',
          params: {
            id: vipSongs.id,
            slug: _vm.song_detail(vipSongs.mp3_title),
            singer: vipSongs.mp3_artist,
            title: vipSongs.mp3_title,
          },
          query: { singer: _vm.getSinger(vipSongs.mp3_artist, vipSongs.category_name) },
        }}},[_c('div',{staticClass:"vip_song clearfix"},[_c('progressive-img',{staticClass:"p_img",attrs:{"blur":0,"alt":vipSongs.mp3_title,"src":vipSongs.mp3_thumbnail_s,"placeholder":"https://alemsesi.com/api/placeholder.webp","fallback":"https://alemsesi.com/api/placeholder.webp"}}),_c('p',{staticClass:"vip_song_title"},[_vm._v(_vm._s(vipSongs.mp3_title))])],1)])],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }