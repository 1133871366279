import Vue from 'vue'
import VueRouter from 'vue-router'
import ExploreVue from '../views/Explore.vue'
import Home from '../views/Home.vue'
import ProfileVue from '../views/Profile.vue'
import RegisterVue from '../views/Register.vue'
import NotFound from '../components/NotFound'

Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
   
  },

  {
    path: '/explore',
    name: 'Explore',
    component: ExploreVue,
    children: [
      {
        path: '/s/:title',
        name: 'Songs',
        component: () => import(/* webpackChunkName: "songlist" */ '../components/SongList.vue'),
        children:[
        {
          path: '/song/:id/:slug',
          name: 'SongDetail',
          component: () => import(/* webpackChunkName: "songdetail" */ '../components/SongDetail.vue'),
          
        },
       ]
      },

      {
        path: ':title',
        name: 'category',
        component: () => import(/* webpackChunkName: "catlist" */ '../components/CatList.vue'),
        children:[
          {
            path: '/cat/:id/:title',
            name: 'cat',
            component: () => import(/* webpackChunkName: "catsongs" */ '../components/CatSongs.vue'),
            children:[
              {
                path: '/song/:id/:slug',
                name: 'SongDetaill',
                component: () => import(/* webpackChunkName: "songdetail" */ '../components/SongDetail.vue'),
                
              }
            ]
          },
        ]
      },

      {
        path: '/p/:id/:title',
        name: 'PlaylistSongs',
        component: () => import(/* webpackChunkName: "plsongs" */ '../components/PlayListSongs.vue'),
        children:[
        {
          path: '/song/:id/:slug',
          name: 'PlayList',
          component: () => import(/* webpackChunkName: "songdetail" */ '../components/SongDetail.vue'),
          
        },
       ]
      },
      
      
      

    ]
  },
  {
    path: '/profile',
    name: 'Profile',
    component: ProfileVue,
   
  },
  {
    path: '/register',
    name: 'Register',
    component: RegisterVue,
   
  },
  {
    path: '/s/:title',
    name: 'Songs',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/SongList.vue'),
   
  },

  {
    path: "/)",
    name: 'Home',
    component: Home
  },

  { path: '*', beforeEnter: (to, from, next) => { next('/404') } },
  {
      path: '/404',
      name: '404',
      component: NotFound
  },
 
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})



export default router
