var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('router-link',{attrs:{"to":{
      name: 'Songs',
      params: {
        title: 'new-songs',
        navTitle: 'Täze aýdymlar',
        api: 'latest',
        page: 1,
        props: true,
      },
      query: { on: 'Täze' },
    }}},[_c('div',{staticClass:"next_btn"},[_c('img',{attrs:{"src":require("@/assets/icons/next_ic.svg")}})])]),_c('div',{staticClass:"text-left"},[_vm._v("Täze ýüklenenler")]),_c('div',{staticClass:"scrolling-wrapper"},_vm._l((_vm.latest),function(latest){return _c('div',{key:latest.id,staticClass:"card_hr"},[_c('router-link',{attrs:{"to":{
          name: 'SongDetail',
          params: {
            id: latest.id,
            slug: _vm.song_detail(latest.mp3_title),
            singer: latest.mp3_artist,
            title: latest.mp3_title,
          },
          query: {
            singer: _vm.getSinger(latest.mp3_artist, latest.category_name),
          },
        }}},[_c('progressive-img',{staticClass:"p_img",attrs:{"src":latest.mp3_thumbnail_s,"alt":latest.mp3_title,"blur":0,"placeholder":"https://alemsesi.com/api/placeholder.webp","fallback":"https://alemsesi.com/api/placeholder.webp"}}),_c('div',{staticClass:"song_title"},[_vm._v(_vm._s(latest.mp3_title))]),_c('div',{staticClass:"song_artist"},[_vm._v(_vm._s(latest.mp3_artist))])],1)],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }