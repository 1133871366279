<template>
  <div>
    <div>
      <div class="login_page" v-if="!this.$cookies.get('_getuser')">
        <div class="login-head" style="margin-top:40px">
          <div class="login-title">Içeri giriň</div>
          <div class="login-body">
            Aýdymlara teswir ýazmak, like we dislike goýmak we ähli
            mümkinçiliklerden peýdalanmak üçin içeri giriň
            <br />
          </div>
        </div>
        <div class="login-form">
          <b-form>
            <b-form-input
              type="number"
              placeholder="Telefon +993.."
              id="phone"
              required
              v-model="phone"
            ></b-form-input>
            <br />

            <b-form-input
              type="password"
              placeholder="Parolyňyz"
              required
              id="password"
              v-model="password"
            ></b-form-input>

            <br />
            <b-button
            style="width:100%"
              block
              variant="danger"
              v-show="!loading"
              @click="login(phone, password)"
              >Içeri gir</b-button
            >
            <b-button style="width:100%" variant="danger" v-show="loading" disabled>
              <b-spinner small></b-spinner>
              Garaşyň...
            </b-button>

          </b-form>
          <br>
          <div class="login-body">Hasabyň ýokmy? Aşakdaka basyp täze hasap açyp bilersiň.</div>
          <br>
          
            <b-button style="width:100%" @click="toRegister()" variant="success">Hasap aç</b-button>

        </div>
      </div>
      <b-overlay :show="loading" variant="transparent" no-wrap> </b-overlay>
      <div
        class="profile-page"
        style="margin-bottom: 60px"
        v-if="this.$cookies.get('_getuser')"
      >
        <div class="profile-head">
          <div class="edit-profile" style="display: none">
            <img src="@/assets/icons/edit_user.svg" />
          </div>
          <div class="profile-h-content">
            <progressive-img
              class="pf-img"
              :blur="0"
              placeholder="https://alemsesi.com/api/placeholder.webp"
              fallback="https://alemsesi.com/api/placeholder.webp"
              :src="profile[0].image"
            />
            <div class="pf-name">{{ profile[0].name }}</div>
            <div class="pf-phones">{{ profile[0].phone }}</div>
          </div>
          <div
            class="logout-profile"
            @click="showMsgBoxOne('Hasabyňyzdan çykmak isleýärsiňizmi?')"
          >
            <img src="@/assets/icons/logout_ic.svg" />
          </div>
        </div>
        <div class="profile-head-st">
          <b-row>
            <b-col
              v-ripple="{ center, class: rippleClass }"
              @click="
                showStaticInfo(
                  'Ýüklenenler',
                  'Älem Sesi arkaly jemi ýükläp alan aýdymlaryňyz ' +
                    profile[0].downloadcount
                )
              "
            >
              <img src="@/assets/icons/downloaded_ic.svg" />
              <div class="prof-static">{{ profile[0].downloadcount }}</div>
            </b-col>
            <b-col
              v-ripple="{ center, class: rippleClass }"
              @click="
                showStaticInfo(
                  'Diňlenenler',
                  'Älem Sesi arkaly jemi diňlän aýdymlaryňyz ' +
                    profile[0].viewcount
                )
              "
            >
              <img src="@/assets/icons/played_ic.svg" />
              <div class="prof-static">
                {{ profile[0].viewcount | formatNumber }}
              </div>
            </b-col>
            <b-col
              v-ripple="{ center, class: rippleClass }"
              @click="
                showStaticInfo(
                  'Teswirler',
                  'Älem Sesinde jemi ýazan teswirleriňiz ' + profile[0].comment
                )
              "
            >
              <img src="@/assets/icons/user_comment.svg" />
              <div class="prof-static">
                {{ profile[0].comment | formatNumber }}
              </div>
            </b-col>
            <b-col
              v-ripple="{ center, class: rippleClass }"
              @click="
                showStaticInfo(
                  'Gazançlar',
                  'Soňky gazanan heniz hasabyňa iberilmedik gazanjyňyz  TMT'
                )
              "
              ><img src="@/assets/icons/earn_ic.svg" />
              <div class="prof-static length_txt">
                {{ profile[0].earning / 2 }}
              </div></b-col
            >
          </b-row>
        </div>

        <b-row style="margin-right: 10px; margin-left: 1px; margin-top: -8px">
          <b-col>
            <div
              v-ripple="{ center, class: rippleClass }"
              class="profile-head-st"
              style="padding-top: 15px; padding-bottom: 13px; margin-right: 1px"
            >
              Teswirler
            </div>
          </b-col>
          <b-col
            ><div
              v-ripple="{ center, class: rippleClass }"
              class="profile-head-st"
              style="padding-top: 15px; padding-bottom: 13px; margin-right: 1px"
            >
              Isleýän aýdmlrm
            </div></b-col
          >
        </b-row>
        <div v-if="loadearn" class="d-flex justify-content-center mb-3">
          <b-spinner variant="light"></b-spinner>
        </div>
        <div v-show="!loadearn">
          <div class="big_title p-2">Telefon belgä iberilen gazanjyňyz</div>
          <div
            class="earn-list"
            v-for="earnlist in earnlist"
            :key="earnlist.id"
            style="margin-top: -4px; margin-left: 10px; margin-bottom: 4px"
          >
            <div class="big_title text-success">
              {{ earnlist.earning + " TMT" }}
            </div>
            <div
              class="pf-phones text-muted"
              style="text-align: left; margin-left: 5px; margin-top: -1px"
            >
              {{ earnlist.date }}
            </div>
          </div>

          <div class="no-earn p-1" v-if="this.earnlist == ''">
            <img src="@/assets/icons/info_ic.svg" width="26px" />
            <div
              class="pf-phones"
              style="margin-top: 10px; margin-left: 10px; margin-right: 10px"
            >
              Telefon belgiňize iberilen gazanjyňyz şu ýerde görünýär. Eger
              gazanjyňyz 10 TMT-dan geçen bolsa hepdäniň her dynç güni telefon
              belgiňize iberilýär. Aýdymlara like ýa-da dislike goýaňyzda pul
              gazanarsyňyz
            </div>
          </div>
        </div>
        <div v-show="!loadhistory">
          <div class="big_title p-2" style="margin-top: 10px">
            Soňky gazanç hereketleriň
          </div>
          <div class="no-earn-history p-1" v-if="this.earnhistory == ''">
            <img src="@/assets/icons/info_ic.svg" width="26px" />
            <div
              class="pf-phones"
              style="margin-top: 10px; margin-left: 10px; margin-right: 10px"
            >
              Gazanç herekediňiz şu ýerde görünýär.
            </div>
          </div>
          <div
            class="earn-list"
            v-for="earnhistory in earnhistory"
            :key="earnhistory.id"
            style="margin-top: -4px; margin-left: 10px; margin-bottom: 4px"
          >
            <div class="big_title text-warning">
              {{ earnhistory.earning + " TMT" }}
            </div>
            <div
              class="pf-phones text-muted"
              style="text-align: left; margin-left: 5px; margin-top: -1px"
            >
              {{ earnhistory.date }}
            </div>
          </div>
        </div>
        <div v-if="loadhistory" class="d-flex justify-content-center mb-3">
          <b-spinner variant="light"></b-spinner>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    phone: "",
    password: "",
    user: "",
    profile: "",
    loading: false,
    earnlist: "",
    earnhistory: "",
    loadearn: false,
    loadhistory: false,
  }),
  created() {},
  beforeMount() {
    if (this.$cookies.get("_getuser")) {
      this.loadProfile();

      console.log("mount");
    }
  },

  methods: {
    showMsgBoxOne(msg) {
      this.boxOne = "";
      this.$bvModal
        .msgBoxConfirm(msg, {
          okTitle: "Hawa",
          cancelTitle: "Ýok",
          okVariant: "danger",
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.$cookies.remove("_getuser");
            this.$cookies.remove("_setuser_key");
            this.$router.push("/");
          } else {
            console.log("dowam");
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    toRegister(){
      this.$router.push("/register");
    },
    showStaticInfo(title, body) {
      this.boxTwo = "";
      this.$bvModal
        .msgBoxOk(body, {
          title: title,
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        })
        .then((value) => {
          this.boxTwo = value;
        })
        .catch((err) => {
          // An error occurred
        });
    },
    async login(phone, passw) {
      this.loading = true;
      try {
        const response = await fetch(
          "https://alemsesi.com/api/wep_api.php?user_login&phone=" +
            phone +
            "&password=" +
            passw
        );

        const data = await response.json();
        this.user = data.ONLINE_MP3;
        this.loading = false;

        if (this.user[0].success == 1) {
          console.log(this.user[0].user_id, "logged");
          this.$cookies.set("_getuser", this.user[0].user_id);
          this.$cookies.set("_setuser_key", passw);
          this.loadProfile();
        } else if (this.user[0].success == 0) {
          console.log("password error");
          this.showStaticInfo(
            "Ýalňyşlyk",
            "Telefon belgiňiz ýa-da parolyňyz ýalňyş. Täzeden barlaň"
          );
          this.alert();
        } else {
          console.log("error");
          this.showStaticInfo(
            "Ýalňyşlyk",
            "Telefon belgiňiz ýa-da parolyňyz ýaňlyş. Täzeden barlaň"
          );
        }
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },

    async loadProfile() {
      this.loading = true;

      try {
        const response = await fetch(
          "https://alemsesi.com/api/wep_api.php?user_profile&user_id=" +
            this.$cookies.get("_getuser") +
            "&key=" +
            this.$cookies.get("_setuser_key")
        );

        const data = await response.json();
        this.profile = data.ONLINE_MP3;
        this.loading = false;
        this.loadEarndone();
        this.loadEarnhistory();
        console.log(this.profile);
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    async loadEarndone() {
      this.loadearn = true;
      try {
        const response = await fetch(
          "https://alemsesi.com/api/wep_api.php?earn_done_list&user_id=" +
            this.$cookies.get("_getuser")
        );

        const data = await response.json();
        this.earnlist = data.ONLINE_MP3;
        this.loadearn = false;
        if (this.earnlist == "") {
          console.log("null");
        } else {
          console.log("full");
        }
        console.log(this.earnlist);
      } catch (error) {
        console.log(error);
        this.loadearn = false;
      }
    },

    async loadEarnhistory() {
      this.loadhistory = true;
      try {
        const headers = new Headers({
          'Authorization': 'Basic ' + this.$store.getters.token,
        });
        const response = await fetch(
          "https://alemsesi.com/api/wep_api.php?earn_list&user_id=" +
            this.$cookies.get("_getuser")
        ,{headers});

        const data = await response.json();
        this.earnhistory = data.ONLINE_MP3;
        this.loadhistory = false;
      } catch (error) {
        console.log(error);
        this.loadhistory = false;
      }
    },
  },
};
</script>

<style scoped>

.big_title {
  font-size: 18px;
  margin-left: 5px;
  color: white;
  text-align: left;
  font-family: "bold", sans-serif;
}
.length_txt {
  position: relative;
  width: 26px;
  right: -35px;
  overflow: hidden;
}

.profile-h-content {
  padding-bottom: 15px;
}
.profile-head {
  position: relative;
  overflow: hidden;
  background: #202020;
  margin: 10px;
  border-radius: 10px;
  padding: 15px;
}
.prof-static {
  font-family: "light", sans-serif;
  color: white;
  margin-top: 5px;
  font-size: 12px;
}
.profile-head-st img {
  width: 17px;
}
.profile-head-st {
  position: relative;
  overflow: hidden;
  padding-bottom: 7px;
  padding-top: 7px;
  background: #202020;
  color: white;
  font-family: "bold", sans-serif;
  font-size: 14px;
  margin: 10px;
  border-radius: 10px;
}
.edit-profile {
  text-align: left;
  margin-left: 5px;
}
.logout-profile {
  top: 13px;
  position: absolute;
  right: 15px;
}
.edit-profile img {
  width: 19px;
  height: 19px;
}
.logout-profile img {
  width: 19px;
  height: 19px;
}
.pf-phones {
  font-family: "light", sans-serif;
  color: rgb(194, 194, 194);
  font-size: 13px;
  margin-top: 5px;
}
.pf-name {
  font-family: "bold", sans-serif;
  font-size: 19px;
  color: white;
}
.pf-img {
  width: 90px;
  height: 90px;
  border-radius: 100%;
}
.login-form {
  margin-top: 20px;
  padding-right: 25px;
  padding-left: 25px;
}
.login-head {
  padding: 10px;
  margin-top: 20px;
}
.login-title {
  color: white;
  font-size: 19px;
  font-family: "bold", sans-serif;
}
.login-body {
  color: rgb(201, 201, 201);
  font-family: "light", sans-serif;
  font-size: 14px;
  line-height: 1.7;
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
}
</style>
