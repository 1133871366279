<template>
  <div style="position: relative; height: 170px;">
  <slick
  ref="slick"
  :options="slickOptions"
  @destroy="handleDestroy"
  @setPosition="handleSetPosition"
  @swipe="handleSwipe"
  @reInit="handleReInit"
  @lazyLoaded="handleLazyLoaded"
  @lazyLoadError="handleLazyLoadError">   
    <div class="singleSong" v-for="single in single" :key="single.id">
      <router-link
           :to="{
             name: 'SongDetail',
             params: {
               id: single.id,
               slug: song_detail(single.mp3_title),
               singer: single.mp3_artist,
               title: single.mp3_title,
             },
             query: {
               singer: getSinger(single.mp3_artist, single.category_name),
             },
           }"
         >
      <div class="background" :style="{ backgroundImage: 'url(' + single.mp3_thumbnail_s + ')' }"></div>
      <img 
       :id="`target-image`"
       :src='single.mp3_thumbnail_s' 
       :alt="single.mp3_title"     
       class="imgSingle" text-left width="100px" height="100px"/>
      <p class="single_song_title">{{single.mp3_title}}</p>
      <img class="logo_art" src="@/assets/logo_art.svg"/>
      </router-link>
      </div>
</slick>
</div>
  
 </template>
 
 <style scoped>
 .singleSong {
   height: 170px;
   position: relative;
   display: block;
   overflow: hidden;
   margin: 10px;
   border-radius: 10px;
   position: relative;
 }
 
 .background {
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   opacity: 0.7;
   background-size: cover;
   background-position: center;
   filter: blur(40px); /* Blur effect */
   z-index: -1; /* Send to the background */
 }
 
 .imgSingle {
   float: left;
   margin-right: 20px;
   margin-left: 30px;
   margin-top: 33.5px;
   border-radius: 10px;
 }
 
 .logo_art {
   margin-top: -30px;
   margin-right: -70px;
   position: absolute;
   opacity: 0.2;
   height: 200px;
   top: 0;
   width: 200px;
   object-fit: cover;
   right: 0;
 }
 
 .single_song_title {
   text-align: left;
   padding-top: 8px;
   color: #ffffff;
   margin-top: 42.5px;
   margin-right: 20px;
   padding-right: 5px;
   font-size: 18px;
   -webkit-box-orient: vertical;
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   vertical-align: middle;
   -webkit-line-clamp: 3;
   font-family: 'bold', sans-serif;
   line-height: 1.2;
 }
 </style>
 

<script>
import Slick from 'vue-slick';
import 'slick-carousel/slick/slick.css';
export default ({
  name: 'single',
  props:['single'],
 data() {
        return {
            slickOptions: {
                slidesToShow: 1,    
                autoplay: true,            
                autoplaySpeed: 1000,
                slidesToScroll: 1,
            },
        };
    },
  components: { Slick },
   methods: {
    song_detail(title) {
      var slug = "";
      var titleLower = title.toLowerCase();
      slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, "e");
      slug = slug.replace(/a|á|à|ã|ả|ạ|ä|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, "a");
      slug = slug.replace(/o|ó|ò|õ|ỏ|ö|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, "o");
      slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ü|ử|ự/gi, "u");
      slug = slug.replace(/c|ç/gi, "c");
      slug = slug.replace(/n|ň/gi, "n");
      slug = slug.replace(/s|ş/gi, "s");
      slug = slug.replace(/y|ÿ|ý/gi, "y");
      slug = slug.replace(/đ/gi, "d");
      slug = slug.replace(/\s*$/g, "");
      slug = slug.replace(/ /g, "-");

      return slug;
    },
     getSinger(text, cat) {
      if (
        (text == "Beýlekiler (Other)") |
        (text == "Halk aýdymlary") |
        (text == "Goşgular (Lyrics)") |
        (text == "Daşary ýurt aýdymlary")
      ) {
        
        
        var catName = cat.replace(/ /g, "");
        return catName;
      } else {
        var noSpacesString = text.replace(/ /g, "");
        return noSpacesString;
      }
  },
  },
  computed: {
    dark() {
      if (!this.palette || !this.palette.DarkVibrant) {
        return `black`;
      }
      return this.palette.DarkVibrant.getHex();
    },
    light() {
      if (!this.palette || !this.palette.LightMuted) {
        return `lightgrey`;
      }
      return this.palette.LightMuted.getHex();
    },
    accent() {
      if (!this.palette || !this.palette.Vibrant) {
        return `crimson`;
      }
      return this.palette.Vibrant.getHex();
    },
  },
});

</script>

