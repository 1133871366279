var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"position":"relative","height":"170px"}},[_c('slick',{ref:"slick",attrs:{"options":_vm.slickOptions},on:{"destroy":_vm.handleDestroy,"setPosition":_vm.handleSetPosition,"swipe":_vm.handleSwipe,"reInit":_vm.handleReInit,"lazyLoaded":_vm.handleLazyLoaded,"lazyLoadError":_vm.handleLazyLoadError}},_vm._l((_vm.single),function(single){return _c('div',{key:single.id,staticClass:"singleSong"},[_c('router-link',{attrs:{"to":{
             name: 'SongDetail',
             params: {
               id: single.id,
               slug: _vm.song_detail(single.mp3_title),
               singer: single.mp3_artist,
               title: single.mp3_title,
             },
             query: {
               singer: _vm.getSinger(single.mp3_artist, single.category_name),
             },
           }}},[_c('div',{staticClass:"background",style:({ backgroundImage: 'url(' + single.mp3_thumbnail_s + ')' })}),_c('img',{staticClass:"imgSingle",attrs:{"id":"target-image","src":single.mp3_thumbnail_s,"alt":single.mp3_title,"text-left":"","width":"100px","height":"100px"}}),_c('p',{staticClass:"single_song_title"},[_vm._v(_vm._s(single.mp3_title))]),_c('img',{staticClass:"logo_art",attrs:{"src":require("@/assets/logo_art.svg")}})])],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }