// store/modules/auth.js
const state = {
  token: 's',
};

const mutations = {
  setToken(state, token) {
    state.token = token;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
