var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"text-left"},[_vm._v("Goşgylar")]),_c('div',{staticClass:"scrolling-wrapper"},_vm._l((_vm.poetry),function(poetry){return _c('div',{key:poetry.id,staticClass:"card_hr"},[_c('router-link',{attrs:{"to":{
            name: 'SongDetail',
            params: {
              id: poetry.id,
              slug: _vm.song_detail(poetry.mp3_title),
              singer: poetry.mp3_artist,
              title: poetry.mp3_title,
            },
            query: { category: _vm.getSinger(poetry.mp3_artist, poetry.category_name) },
          }}},[_c('progressive-img',{staticClass:"p_img",attrs:{"src":poetry.mp3_thumbnail_s,"alt":poetry.mp3_title,"blur":0,"placeholder":"https://alemsesi.com/api/placeholder.webp","fallback":"https://alemsesi.com/api/placeholder.webp"}}),_c('div',{staticClass:"song_title"},[_vm._v(_vm._s(poetry.mp3_title))]),_c('div',{staticClass:"song_artist"},[_vm._v(_vm._s(poetry.mp3_artist))])],1)],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }