<template>
  <div style="margin-bottom: 65px">
    <router-view ref="rv" />
    <div v-show="isExactActive">
      <b-overlay :show="loading" variant="transparent" no-wrap> </b-overlay>
      <div class="nav_explore">
        <div class="explore_title">Explore</div>
        <div class="explore-search">
          <autocomplete
            :search="search_song"
            placeholder="gözleg.."
            aria-label="gözleg.."
            :get-result-value="getResultValue"
            @submit="onSubmit"
          ></autocomplete>
        </div>
      </div>

      <div class="array-card" style="margin-top: 120px" v-show="!loading">
        <b-container class="vipSongs">
          <b-row>
            <b-col cols="6">
              <router-link
                :to="{
                  name: 'Songs',
                  params: {
                    title: 'new-songs',
                    api: 'latest',
                    page: 1,
                  },
                  query: { on: 'Täze' },
                }"
              >
                <div class="card-pl bg_new">
                  <div class="card-tv">Täzeler</div>
                  <div class="card-img bg-new-sm"></div>
                </div>
              </router-link>
            </b-col>

            <b-col cols="6">
              <router-link
                :to="{
                  name: 'Songs',
                  params: {
                    title: 'trend-songs',
                    api: 'trend_page',
                    page: 1,
                  },
                  query: { on: 'Trend' },
                }"
              >
                <div class="card-pl bg-trend">
                  <div class="card-tv">Trend</div>
                  <div class="card-img bg-trend-sm"></div>
                </div>
              </router-link>
            </b-col>
            <b-col cols="6">
              <router-link
                :to="{
                  name: 'category',
                  params: {
                    title: 'category',
                  },
                }"
              >
                <div class="card-pl bg-cat">
                  <div class="card-tv">Kategoriya</div>
                  <div class="card-img bg-cat-sm"></div>
                </div>
              </router-link>
            </b-col>
            <b-col cols="6" style="display: none">
              <div class="card-pl bg-artist">
                <div class="card-tv">Bagşylar</div>
                <div class="card-img bg-artist-sm"></div>
              </div>
            </b-col>
            <b-col cols="6">
              <router-link
                :to="{
                  name: 'Songs',
                  params: {
                    title: 'most-downloads',
                    navTitle: 'Iň köp ýüklenen',
                    api: 'top_download',
                    page: 1,
                  },
                  query: { on: 'Iň köp ýüklenen' },
                }"
              >
                <div class="card-pl bg-topdown">
                  <div class="card-tv">Iň köp ýüklenen</div>
                  <div class="card-img bg-topdown-sm"></div>
                </div>
              </router-link>
            </b-col>
            <b-col cols="6">
              <router-link
                :to="{
                  name: 'Songs',
                  params: {
                    title: 'popular',
                    navTitle: 'Populýarny',
                    api: 'top_rate',
                    page: 1,
                  },
                  query: { on: 'Populýarny' },
                }"
              >
                <div class="card-pl bg-popular">
                  <div class="card-tv">Populýarny</div>
                  <div class="card-img bg-popular-sm"></div>
                </div>
              </router-link>
            </b-col>

            <b-col cols="6" v-for="playlist in playlist" :key="playlist.pid">
              <router-link
                :to="{
                  name: 'PlaylistSongs',
                  params: {
                    id: playlist.pid,
                    title: song_detail(playlist.playlist_name),
                    page: 1,
                  },
                  query: { on: playlist.playlist_name },
                }"
              >
                <div class="card-pl">
                  <div class="card-tv">{{ playlist.playlist_name }}</div>

                  <progressive-img
                    class="card-img"
                    :blur="0"
                    placeholder="https://alemsesi.com/api/placeholder.webp"
                    fallback="https://alemsesi.com/api/placeholder.webp"
                    :src="playlist.playlist_image_thumb"
                  />
                </div>
              </router-link>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
  </div>
</template>
<script>
import Autocomplete from "@trevoreyre/autocomplete-vue";
import "@trevoreyre/autocomplete-vue/dist/style.css";

const wikiUrl = "https://alemsesi.com/api/";
const params = "search_song&page=1";
export default {
  name: "explore",
  keepAlive: true,

  components: {
    Autocomplete,
  },
  data() {
    return {
      loaded: true,
      loading: false,
      playlist: "",
      pages: 1,
      page_s: 1,
      isExactActive: true,
    };
  },

  updated() {
    this.isExactActive = typeof this.$refs.rv === "undefined";
  },

  mounted() {
    this.isExactActive = typeof this.$refs.rv === "undefined";
    
    this.loading = true;
    this.loaded = false;

    fetch(
      "https://alemsesi.com/api/wep_api.php?" +
        "playlist" +
        "&page=" +
        this.pages
    )
      .then(response => response.json())
      .then(data => {
        this.playlist = data.ONLINE_MP3;
        this.loading = false;
        this.loaded = true;
      })
      .catch(error => {
        console.log(error);
        this.loaded = false;
        this.loading = false;
      });
  },
 
  methods: {
    
    search_song(input) {
      const headers = new Headers({
          'Authorization': 'Basic ' + this.$store.getters.token,
        });
      const url = `https://alemsesi.com/api/wep_api.php?song_search&page=1&search_text=${encodeURI(
        input
      )}`;

      return new Promise((resolve) => {
        if (input.length < 3) {
          return resolve([]);
        }

        fetch(url, {headers})
          .then((response) => response.json())
          .then((data) => {
            resolve(data.ONLINE_MP3.search_songs);
            
          });
      });
    },
    getResultValue(result) {
      
      return result.mp3_title;
    },

    getSinger(text, cats) {
      if (
        (text == "Beýlekiler (Other)") |
        (text == "Halk aýdymlary") |
        (text == "Goşgular (Lyrics)") |
        (text == "Daşary ýurt aýdymlary")
      ) {
        var catName = cats.replace(/ /g, "");
        return catName;
      } else {
        var noSpacesString = text.replace(/ /g, "");
        return noSpacesString;
      }
    },

    // Open the selected article in
    // a new window
    onSubmit(result) {
      this.$router.push({
        name: "SongDetail",
        params: {
          id: result.id,
          slug: this.song_detail(result.mp3_title),
          singer: result.mp3_artist,
        },
      });
    },

    song_detail(title) {
      var slug = "";
      var titleLower = title.toLowerCase();
      slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, "e");
      slug = slug.replace(/a|á|à|ã|ả|ạ|ä|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, "a");
      slug = slug.replace(/o|ó|ò|õ|ỏ|ö|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, "o");
      slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ü|ử|ự/gi, "u");
      slug = slug.replace(/c|ç/gi, "c");
      slug = slug.replace(/n|ň/gi, "n");
      slug = slug.replace(/s|ş/gi, "s");
      slug = slug.replace(/y|ÿ|ý/gi, "y");
      slug = slug.replace(/đ/gi, "d");
      slug = slug.replace(/\s*$/g, "");
      slug = slug.replace(/ /g, "-");

      return slug;
    },
  },
};
</script>

<style>
.row > * {
  padding-right: calc(var(--bs-gutter-x) * 0) !important;
  padding-left: calc(var(--bs-gutter-x) * 0) !important;
}
.card-img {
  height: 65px;
  border-radius: 0px !important;
  float: right;
  transform: rotate(25deg);
  position: absolute !important;
  right: -20px;
  width: 65px !important;
  top: 25px;
  width: 65px;
}
.card-tv {
  text-align: left;
  margin-left: 10px;
  font-size: 14px;
  text-overflow: ellipsis;
  font-family: "bold", sans-serif;
  color: white;
  padding-top: 10px;
  margin-right: 55px;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  vertical-align: middle;
  -webkit-line-clamp: 3;
}
.array-card {
  margin-left: 7px;
  margin-right: 1px;
}
.card-pl {
  background: #2b2b2b;
  position: relative;
  overflow: hidden;
  height: 90px;
  border-radius: 4px;
  margin-right: 14px;
  margin-bottom: 15px;
}
.bg_new {
  background: #3f51b5;
}
.bg-new-sm {
  background: #42a5f5;
}
.bg-trend {
  background: #ff6f00;
}
.bg-trend-sm {
  background: #ffab40;
}
.bg-cat {
  background: #f50057;
}
.bg-cat-sm {
  background: #c51162;
}

.bg-artist {
  background: #512da8;
}
.bg-artist-sm {
  background: #b388ff;
}
.bg-topdown {
  background: #009688;
}
.bg-topdown-sm {
  background: #0ac5b3;
}

.bg-popular {
  background: #03a2b6;
}
.bg-popular-sm {
  background: #0cb9cf;
}
[data-position='above'] .autocomplete-result-list {
  border-bottom: none;
  border-radius: 8px 8px 0 0;
}
.nav_explore {
  height: auto;
  background: #131313;
  position: fixed;
  width: 100%;
  top: 0;
  padding: 10px;
  z-index: 9;
}
.explore_title {
  text-align: left;
  font-size: 23px;
  color: white;
  margin-top: 6px;
  font-family: "bold", sans-serif;
  margin-left: 5px;
}

.explore-search {
  padding-left: 6px;
  padding-right: 6px;
  padding-bottom: 5px;
  padding-top: 10px;
}
.explore-search input {
  width: 100%;
  height: 45px;
  border-radius: 2px;
  border: none;
  padding-right: 10px;
  font-family: "bold", sans-serif;
  font-size: 15px;
  color: rgb(0, 0, 0);
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(51, 51, 51);
  opacity: 1; /* Firefox */
}
.explore-search input:focus {
  outline: none;
}
</style>
