<template>
 <div>
   <div class="singleSong" :style="{ background: dark }" v-for="single1 in single1" :key="single1.songs_list[0].id">
    
     <router-link
          :to="{
            name: 'SongDetail',
            params: {
              id: single1.songs_list[0].id,
              slug: song_detail(single1.songs_list[0].mp3_title),
              singer: single1.songs_list[0].mp3_artist,
              title: single1.songs_list[0].mp3_title,
              
            },
            query: {
              singer: getSinger(single1.banner_title, single1.banner_title),
            },
          }"
        >
     <img 
      :id="`target-image`"
      :src='single1.banner_image' 
      :alt="single1.songs_list[0].mp3_title " 
      class="imgSingle" text-left width="100%" height="auto"/>
     </router-link>
     </div>
   
 </div>
</template>

<script>
import Vibrant from "node-vibrant";

export default ({
  name: 'single1',
  props:['single1'],
  

  data:() =>{
    return{
      palette: {}
      
    }
  },
   methods: {
    getPalette() {
      const img = document.getElementById(`target-image`)
      const vibrant = new Vibrant(img.src);
      vibrant.getPalette().then(
        (palette) => this.palette = palette,
        (reason) => {
          console.error(reason);
        }
      )
    },
    song_detail(title) {
      var slug = "";
      var titleLower = title.toLowerCase();
      slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, "e");
      slug = slug.replace(/a|á|à|ã|ả|ạ|ä|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, "a");
      slug = slug.replace(/o|ó|ò|õ|ỏ|ö|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, "o");
      slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ü|ử|ự/gi, "u");
      slug = slug.replace(/c|ç/gi, "c");
      slug = slug.replace(/n|ň/gi, "n");
      slug = slug.replace(/s|ş/gi, "s");
      slug = slug.replace(/y|ÿ|ý/gi, "y");
      slug = slug.replace(/đ/gi, "d");
      slug = slug.replace(/\s*$/g, "");
      slug = slug.replace(/ /g, "-");

      return slug;
    },
     getSinger(text, cat) {
      if (
        (text == "Beýlekiler (Other)") |
        (text == "Halk aýdymlary") |
        (text == "Goşgular (Lyrics)") |
        (text == "Daşary ýurt aýdymlary")
      ) {
        
        
        var catName = cat.replace(/ /g, "");
        return catName;
      } else {
        var noSpacesString = text.replace(/ /g, "");
        return noSpacesString;
      }
  },
  },
  computed: {
    dark() {
      if (!this.palette || !this.palette.DarkVibrant) {
        return `black`;
      }
      return this.palette.DarkVibrant.getHex();
    },
    light() {
      if (!this.palette || !this.palette.LightMuted) {
        return `lightgrey`;
      }
      return this.palette.LightMuted.getHex();
    },
    accent() {
      if (!this.palette || !this.palette.Vibrant) {
        return `crimson`;
      }
      return this.palette.Vibrant.getHex();
    },
  },
});

</script>

<style scoped>
.singleSong{
  height: 170px;
  position: relative;
  display: block;
  overflow: hidden;
  background: #6c6c6c !important;
  margin: 10px;
}
.imgSingle{

}
.logo_art{
  margin-top:-30px;
  margin-right: -70px;
  position: absolute;
  opacity: 0.06;
  height: 200px;
  top: 0;
  width: 200px;
  object-fit: cover;
  right: 0;
}
.single_song_title{
  text-align: left;
padding-top: 8px;
color: #ffffff;
margin-top: 42.5px;
margin-right: 20px;
padding-right: 5px;
font-size: 18px;
-webkit-box-orient: vertical;
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
vertical-align: middle;
-webkit-line-clamp: 3;
font-family: 'bold', sans-serif;
line-height: 1.2;
}
</style>