<template>
  <div>
    <router-link
      :to="{
        name: 'Songs',
        params: {
          title: 'trend-songs',
          navTitle: 'Trend',
          api: 'trend_page',
          page: 1,
          props: true,
        },
        query: { on: 'Trend' },
      }"
    >
      <div class="next_btn"><img src="@/assets/icons/next_ic.svg" /></div>
    </router-link>
    <div class="text-left">Bu hepde trend</div>

    <div class="scrolling-wrapper">
      <div
        v-for="trend in trend"
        :key="trend.id"
        class="card"
      >
        <router-link
          :to="{
            name: 'SongDetail',
            params: {
              id: trend.id,
              slug: song_detail(trend.mp3_title),
              singer: trend.mp3_artist,
              title: trend.mp3_title,
            },
            query: { singer: getSinger(trend.mp3_artist, trend.category_name) },
          }"
        >
          <progressive-img
            :src="trend.mp3_thumbnail_s"
            class="p_img"
            :alt="trend.mp3_title"
            :blur="0"
            placeholder="https://alemsesi.com/api/placeholder.webp"
            fallback="https://alemsesi.com/api/placeholder.webp"
          />
        </router-link>
      </div>
    </div>
  </div>
</template>

<style scoped>
.scrolling-wrapper {
  margin-left: 5px;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}
.card {
  margin: 5px;
  background: none;
  display: inline-block;
}
.text-left {
  text-align: left;
  font-size: 19px;
  margin-left: 10px;
  overflow-x: hidden;
  margin-bottom: 5px;
  font-family: "bold", sans-serif;
  overflow: hidden;
  color: white;
}
.p_img {
  /* position: absolute; */
  /* top: 0; */
  /* left: 0; */
  /* width: 100%; */
  height: 120px;
  width: 120px;
  border-radius: 5px;
  /* height: auto; */
}
</style>

<script>
export default {
  name: "Trend",
  props: ["trend"],
  methods: {
    song_detail(title) {
      var slug = "";
      var titleLower = title.toLowerCase();
      slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, "e");
      slug = slug.replace(/a|á|à|ã|ả|ạ|ä|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, "a");
      slug = slug.replace(/o|ó|ò|õ|ỏ|ö|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, "o");
      slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ü|ử|ự/gi, "u");
      slug = slug.replace(/c|ç/gi, "c");
      slug = slug.replace(/n|ň/gi, "n");
      slug = slug.replace(/s|ş/gi, "s");
      slug = slug.replace(/y|ÿ|ý/gi, "y");
      slug = slug.replace(/đ/gi, "d");
      slug = slug.replace(/\s*$/g, "");
      slug = slug.replace(/ /g, "-");

      return slug;
    },
    getSinger(text, cat) {
      if (
        (text == "Beýlekiler (Other)") |
        (text == "Halk aýdymlary") |
        (text == "Goşgular (Lyrics)") |
        (text == "Daşary ýurt aýdymlary")
      ) {
        var catName = cat.replace(/ /g, "");
        return catName;
      } else {
        var noSpacesString = text.replace(/ /g, "");
        return noSpacesString;
      }
    },
  },
};
</script>
