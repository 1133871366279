import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueMeta from 'vue-meta'
import { ModalPlugin, FormInputPlugin, LayoutPlugin, ButtonPlugin, OverlayPlugin, SpinnerPlugin, CarouselPlugin, CollapsePlugin } from 'bootstrap-vue' 
import 'bootstrap/dist/css/bootstrap.min.css'
import VueProgressiveImage from 'vue-progressive-image'
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'
import VueCookies from 'vue-cookies'
import firebase from 'firebase/compat/app';
import "firebase/compat/firestore";
import "firebase/compat/analytics";
import Vuex from 'vuex';
import auth from './store/modules/auth';
import store from './store'
import Carousel from 'vue-carousel';




const firebaseConfig = {
  apiKey: "AIzaSyAiUfF-mREz44vLplLBe7qGG9DfpGvJTeM",
  authDomain: "alem-sesi.firebaseapp.com",
  databaseURL: "https://alem-sesi.firebaseio.com",
  projectId: "alem-sesi",
  storageBucket: "alem-sesi.appspot.com",
  messagingSenderId: "584136414052",
  appId: "1:584136414052:web:de361ea900d6a8f28c6071"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();


Vue.use(LayoutPlugin)
Vue.use(ModalPlugin)
Vue.use(ButtonPlugin)
Vue.use(FormInputPlugin)
Vue.use(OverlayPlugin)
Vue.use(SpinnerPlugin)
Vue.use(CarouselPlugin)
Vue.use(Carousel)
Vue.use(CollapsePlugin)



Vue.use(VueCookies)
Vue.$cookies.config('30d')
Vue.use(VueProgressiveImage);
Vue.config.productionTip = false;

Vue.use(VuePlyr)
Vue.use(VueMeta)

Vue.config.productionTip = false



Vue.filter('formatNumber', (n) => {
 var ends = ['K','M','B','T']
  if(n.length<4){
    return n;
  }else if(n.length==5){
    return `${n[0]}${n[1]!=''?`${n[1]}.${n[1]}`:''}${ends[Math.floor((n.length-1)/3)-1]}`;
  }
  else if(n.length==6){
    return `${n[0]}${n[1]!='0'?`${n[1]}${n[1]}.${n[1]}`:''}${ends[Math.floor((n.length-1)/3)-1]}`;
  }
  else{
    return `${n[0]}${n[1]!='0'?`.${n[1]}`:''}${ends[Math.floor((n.length-1)/3)-1]}`;
  }
})



new Vue({
  router,
  store,
  

  render: h => h(App)
}).$mount('#app')


