import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: "a25ha2xzamZiYXNoamRmQEBfXzpATktzZDM0NXNkdmRmc2Q="
  },
  mutations: {
    setToken (state, token) {
      state.token = token
    }
  },
  actions: {
    setToken ({ commit }, token) {
      commit('setToken', token)
    }
  },
  getters: {
    token: state => state.token
  }
})
