<template>
  <div v-if="homeReklama[0].img !== null">
  <div style="position: relative; height: 170px;">
  <slick
  ref="slick"
  :options="slickOptions"
  @destroy="handleDestroy"
  @setPosition="handleSetPosition"
  @swipe="handleSwipe"
  @reInit="handleReInit"
  @lazyLoaded="handleLazyLoaded"
  @lazyLoadError="handleLazyLoadError">    
    <div v-for="homeReklama in homeReklama" :key="homeReklama.id" @click="pushGo(homeReklama.ads_link)">
   
          <img 
          :src="homeReklama.ads_image"
          style="width: 100%; height: 170px; object-fit: cover;"
          placeholder="https://alemsesi.com/api/placeholder.webp"
          fallback="https://alemsesi.com/api/placeholder.webp"
        />
     
   
  </div>
</slick>
</div>
</div>
</template>

<style>
.slick-prev , .slick-arrow {display: none !important;}
</style>
<script>
import Slick from 'vue-slick';

export default {
  components: { Slick },

  name: "HomeReklama",
  props: ["homeReklama"],
  data() {
        return {
            slickOptions: {
                slidesToShow: 1,    
                autoplay: true,            
                autoplaySpeed: 1000,
                slidesToScroll: 1,
            },
        };
    },
  methods: {
    pushGo(url) {
      window.open(url);
      console.log(url);
    },
  },
};
</script>
