var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"scrolling-wrapper"},_vm._l((_vm.playlist),function(playlist){return _c('div',{key:playlist.pid,staticClass:"card_hr"},[_c('router-link',{attrs:{"to":{
                  name: 'PlaylistSongs',
                  params: {
                    id: playlist.pid,
                    title: _vm.song_detail(playlist.playlist_name),
                    page: 1,
                  },
                  query: { on: playlist.playlist_name },
                }}},[_c('progressive-img',{staticClass:"p_img",attrs:{"src":playlist.playlist_image_thumb,"alt":playlist.playlist_name,"blur":0,"placeholder":"https://alemsesi.com/api/placeholder.webp","fallback":"https://alemsesi.com/api/placeholder.webp"}}),_c('div',{staticClass:"song_title"},[_vm._v(_vm._s(playlist.playlist_name))])],1)],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }